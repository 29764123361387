// Copyright 2025 Merit International Inc. All Rights Reserved

import { Body, Heading, useTheme } from "@merit/frontend-components";
import { FlatList, ScrollView, StyleSheet, View } from "react-native";
import { useFeatureFlags } from "@src/hooks/featureFlags";
import { useFlaggedLayout } from "@src/hooks/useFlaggedLayout";
import Constants from "expo-constants";
import type { Configuration as EnvConfig } from "@src/configuration";
import type { TextStyle, ViewStyle } from "react-native";

const SCREEN_NAME = "AppInfo";

const envConfig = Constants.manifest?.extra as EnvConfig;

export const AppInfoScreen = () => {
  const { theme } = useTheme();
  const styles = StyleSheet.create<{
    readonly bold: TextStyle;
    readonly container: ViewStyle;
    readonly featureFlagRow: ViewStyle;
  }>({
    bold: {
      fontWeight: theme.fontWeights.semiBold,
    },
    container: {
      backgroundColor: theme.colors.background.white,
      flex: 1,
      padding: theme.spacing.xl,
    },
    featureFlagRow: {
      flexDirection: "row",
      gap: theme.spacing.s,
      padding: theme.spacing.s,
    },
  });

  const { DefaultLayout } = useFlaggedLayout();
  const featureFlags = useFeatureFlags();

  return (
    <DefaultLayout
      testProps={{
        elementName: "appinfo",
        screenName: SCREEN_NAME,
      }}
      title="AppInfo"
    >
      <ScrollView style={styles.container}>
        <Heading bold level="3">
          Known Feature Flags For Provider: {envConfig.featureFlags.provider}
        </Heading>
        <FlatList
          data={Object.entries(featureFlags)}
          renderItem={({ item: [flagName, flagValue] }) => (
            <View style={styles.featureFlagRow}>
              <Body style={styles.bold}>{flagName}:</Body>
              <Body>{String(flagValue)}</Body>
            </View>
          )}
        />
      </ScrollView>
    </DefaultLayout>
  );
};
