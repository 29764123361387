// Copyright 2025 Merit International Inc. All Rights Reserved

import { useApi } from "@src/api/api";
import {
  useAppConstantsStore,
  useAuthStore,
  useBaseFieldIdStore,
  useConfigurationStore,
} from "@src/stores";
import { useEffect } from "react";

export const useInitializeBaseFieldIds = () => {
  const constants = useAppConstantsStore();
  const { fieldIds, updateFieldIds } = useBaseFieldIdStore();
  const { configuration } = useConfigurationStore();
  const { selectedOrgId } = useAuthStore();
  const { api } = useApi();

  const isInitialized = fieldIds !== undefined;

  useEffect(() => {
    (async () => {
      if (configuration === undefined) {
        return;
      }

      if (!isInitialized && selectedOrgId !== null) {
        await updateFieldIds(api, configuration, constants, selectedOrgId);
      }
    })();
  }, [api, configuration, constants, fieldIds, updateFieldIds, selectedOrgId, isInitialized]);

  return { isInitialized };
};
