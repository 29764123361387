// Copyright 2025 Merit International Inc. All Rights Reserved

import { Pressable, StyleSheet } from "react-native";
import { useNavigation } from "@react-navigation/native";
import type { NativeStackNavigationProp } from "@react-navigation/native-stack";
import type { ReactNode } from "react";
import type { RouteParams } from "@src/Router";
import type { ViewStyle } from "react-native";

type Navigation = NativeStackNavigationProp<RouteParams, "AppInfo">;

type AppInfoLongPressableProps = {
  readonly children: ReactNode;
};

export const AppInfoLongPressable = ({ children }: AppInfoLongPressableProps) => {
  const styles = StyleSheet.create<{
    readonly container: ViewStyle;
  }>({
    container: {
      cursor: "auto",
    },
  });

  const navigation = useNavigation<Navigation>();

  return (
    <Pressable
      onLongPress={() => {
        navigation.navigate("AppInfo");
      }}
      style={styles.container}
    >
      {children}
    </Pressable>
  );
};
