// Copyright 2025 Merit International Inc. All Rights Reserved

import { datadogRum as DdRum } from "@datadog/browser-rum";
import { FlagEffect } from "./FlagEffect";
import { View } from "react-native";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import { useAuthStore } from "@src/stores";
import { useEffect, useState } from "react";
import { useLoadedConfigurationState } from "@src/hooks/useLoadedConfigurationState";
import type { LDEvaluationDetail, ProviderConfig } from "launchdarkly-react-client-sdk";
import type { ProviderFunctionalComponent } from "../../types";

export const Provider: ProviderFunctionalComponent = ({ children }) => {
  const { configuration } = useLoadedConfigurationState();
  const { profile, selectedOrgId } = useAuthStore(state => ({
    profile: state.profile,
    selectedOrgId: state.selectedOrgId,
  }));

  const [ResolvedProvider, setResolvedProvider] = useState<ProviderFunctionalComponent>();

  useEffect(() => {
    (async () => {
      const ldProviderConfig: ProviderConfig = {
        clientSideID: configuration.launchDarklyClientID,
        context:
          profile?.entityID === undefined || selectedOrgId === null
            ? undefined
            : {
                kind: "multi",
                org: {
                  key: selectedOrgId,
                },
                user: {
                  key: profile.entityID,
                },
              },
        options: {
          inspectors: [
            {
              method: (key: string, detail: LDEvaluationDetail) => {
                DdRum.addFeatureFlagEvaluation(key, detail.value);
              },
              name: "dd-inspector",
              type: "flag-used",
            },
          ],
        },
      };
      const LDProvider = await asyncWithLDProvider(ldProviderConfig);
      setResolvedProvider(() => LDProvider);
    })();
  }, [configuration.launchDarklyClientID, profile?.entityID, selectedOrgId]);

  if (ResolvedProvider === undefined) {
    return <View />;
  }

  return (
    <ResolvedProvider>
      <FlagEffect />
      {children}
    </ResolvedProvider>
  );
};
