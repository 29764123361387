// Copyright 2025 Merit International Inc. All Rights Reserved

import { LaunchDarklyProvider, NoneProvider, UnleashProvider } from "./providers";
import Constants from "expo-constants";
import type { Configuration as EnvConfig } from "@src/configuration";
import type { ReactNode } from "react";

const envConfig = Constants.manifest?.extra as EnvConfig;

type FeatureFlagsProviderProps = {
  readonly children?: ReactNode;
};

export const FeatureFlagsProvider = ({ children }: FeatureFlagsProviderProps) => {
  // eslint-disable-next-line functional/no-let, @typescript-eslint/init-declarations
  let Provider;
  switch (envConfig.featureFlags.provider) {
    case "LaunchDarkly":
      Provider = LaunchDarklyProvider;
      break;
    case "Unleash":
      Provider = UnleashProvider;
      break;
    case "None":
      Provider = NoneProvider;
      break;
    default:
      Provider = NoneProvider;
  }

  return <Provider>{children}</Provider>;
};
