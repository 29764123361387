// Copyright 2025 Merit International Inc. All Rights Reserved

import { useEffect, useState } from "react";
import { useInitializeBaseFieldIds } from "./useInitializeStoreData";
import { useInitializeConfiguration } from "./useInitializeConfiguration";
import { useInitializeDatadog } from "./useInitializeDatadog";
import { useInitializeFonts } from "./useInitializeFonts";

export const useInitializeApp = () => {
  const [isAppLoaded, setIsAppLoaded] = useState<boolean>(false);

  const { isInitialized: isConfigurationInitialized } = useInitializeConfiguration();
  const { isInitialized: areBaseFieldIdsInitialized } = useInitializeBaseFieldIds();
  const { isWorkDone: isDatadogWorkDone } = useInitializeDatadog();
  const { isInitialized: areFontsInitialized } = useInitializeFonts();

  useEffect(() => {
    if (
      !isAppLoaded &&
      areFontsInitialized &&
      isDatadogWorkDone &&
      isConfigurationInitialized &&
      areBaseFieldIdsInitialized
    ) {
      setIsAppLoaded(true);
    }
  }, [
    areBaseFieldIdsInitialized,
    areFontsInitialized,
    isAppLoaded,
    isConfigurationInitialized,
    isDatadogWorkDone,
  ]);

  return { isAppLoaded };
};
