// Copyright 2025 Merit International Inc. All Rights Reserved

import { useAuthStore } from "@src/stores";
import { useEffect } from "react";
import { useFeatureFlagsStore } from "@src/stores/featureFlagsStore";
import { useFlags, useLDClient } from "launchdarkly-react-client-sdk";
import type { FeatureFlags } from "@src/configuration/featureFlags";

export const FlagEffect = () => {
  const client = useLDClient();
  const flags = useFlags<FeatureFlags>();
  const { setFeatureFlags, setHasHydrated } = useFeatureFlagsStore(state => ({
    setFeatureFlags: state.setFeatureFlags,
    setHasHydrated: state.setHasHydrated,
  }));
  const { profile, selectedOrgId } = useAuthStore(state => ({
    profile: state.profile,
    selectedOrgId: state.selectedOrgId,
  }));

  useEffect(() => {
    setFeatureFlags(flags);
    setHasHydrated(true);
  }, [flags, setFeatureFlags, setHasHydrated]);

  useEffect(() => {
    (async () => {
      await client?.identify({
        kind: "multi",
        org: {
          key: selectedOrgId,
        },
        user: {
          key: profile?.entityID,
        },
      });
    })();
  }, [client, profile?.entityID, selectedOrgId]);

  return null;
};
